const config = {
	"gatsby": {
		"pathPrefix": "/docs",
		"siteUrl": "https://api.sensaimetrics.io",
		"gaTrackingId": null
	},
	"header": {
		"logo": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA0CAMAAAAQevCgAAAARVBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////9X1DS3AAAAFnRSTlMA2W9c9qQeCugDEzT80ceWhGRCKrFOwi2gAgAAAZhJREFUSMfNlslygzAQRLXvKxL6/0+NkO3ELgMa3dIH4PKqtfR0gdCVim8sGrQgTVTr8gWOmOrakCIaaJJVe8nBrAx37U0qz6023z7lqlkyeVrRGyttfTuTuz71U5MHdGVlQ7uUc8mcnTBrtwr2xMTdM80l+WFCVQPo3Upw10D6O4rNK7D48yjkik7OvPBvpUnitpNdYAlhnFtlgjUi9jdLdksOxrBIsx/RtBbKvMcMsjZ7krowm1T+nbNtNtvi0VLjicezzguBHgk2hoZoTekjpQDNo71LImAibFRJ1EYgZbUTgXFliinOoogSVIqb6vVk8ibRrgKss6mqEulHhi0OArAdyo4ZTrirb6Uv087rne2l162lNFOLSjac0dnC3I4yk3rciUaUScln2aFMHEyqx/Xi0hlEgEwMB8PsImPFMqM9+Y/M7n+Z/glj+uW8mOOeZozmbmT/xYxkqPtB3XvrmE8m9XnVk9pZZjThYyE5dcbwYw4kidBfmM68vhBaZuAyOOlVpja2LyK612Fa9UkMl+UNiYuW+gHo1jdzrxoXVwAAAABJRU5ErkJggg==",
		"logoLink": "https://api.sensaimetrics.io/",
		"title": "Sensai API Docs",
		"githubUrl": "",
		"helpUrl": "",
		"tweetText": "",
		"links": [
			{ "text": "", "link": ""}
		],
	},
	"sidebar": {
		"forcedNavOrder": [
			"/overview",
			"/authorization",
			"/orders",
			"/products",
		],
		"links": [
			{ "text": "Swagger", "link": "https://api.sensaimetrics.io/swagger"},
		],
		"frontline": false,
		"ignoreIndex": true,
	},
	"siteMetadata": {
		"title": "Sensai API Docs",
		"description": "Documentation for Sensai API",
		"ogImage": null,
		"docsLocation": "https://api.sensaimetrics.io",
		"favicon": "https://sensaimetrics.io/favicon.ico"
	},
};

module.exports = config;